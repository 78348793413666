import React, { useState, useEffect, useRef } from "react";
import FindOutMoreBtn from "../find-out-more-btn/find-out-more-btn";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";

export default function Popup({ data, variant, biopage, shouldOpen = false }) {
  const [isOpen, setIsOpen] = useState(shouldOpen);
  const popupRef = useRef(null);
  const scrollPos = useRef(0);

  if (variant !== "collage" && isOpen && typeof window !== "undefined" && window.utag) {
    const location = window.location.href;
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: `${location.replace(/\/$/, "")}/pop-up`,
      pageName: "pop up",
      pageType: "product information",
      pageSubCategory: location.split("/").pop().replace(/-/g, " "),
      pageCategory: location,
      rawDataLayer: "10451v91",
    });
  }

  useEffect(() => {
    if (data && (data.name || data.description)) {
      setIsOpen(true);
    }
  }, [data]);

  const closePopup = (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleClick = (eventContent) => {
    if (variant !== "collage" && typeof window !== "undefined" && window.utag) {
      console.log(variant);
      const location = window.location.href;
      const commonData = {
        tealiumEvent: "link",
        pageUrl: `${location}pop-up`,
        pageName: "pop up",
        pageType: "product information",
        eventCategory: "content",
        eventAction: "button",
        eventContent,
        pageSubCategory: location.split("/").pop().replace(/-/g, " "),
      };

      const rawDataLayerMap = {
        close: "10451v92",
        download: "10451v93",
      };

      if (rawDataLayerMap[eventContent]) {
        triggerTealiumEvent({ ...commonData, rawDataLayer: rawDataLayerMap[eventContent] });
      }
    }
  };

  const addNoScroll = (scrollPos) => {
    document.querySelector("body").classList.add("body--modal-open");
    document.querySelector("body").style.top = `-${scrollPos}px`;
  };

  const removeNoScroll = (scrollPos) => {
    document.querySelector("body").classList.remove("body--modal-open");
    document.querySelector("body").style.top = null;
    window.scrollTo({
      top: scrollPos,
      left: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    if (isOpen) {
      scrollPos.current = window.scrollY;
      const popupEl = popupRef?.current;
      const focusableElements = popupEl?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          } else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      };

      firstElement.focus();

      popupEl.addEventListener("keydown", handleTabKeyPress);
      document.addEventListener("mousedown", handleClickOutside);
      addNoScroll(scrollPos.current);

      return () => {
        popupEl.removeEventListener("keydown", handleTabKeyPress);
        document.removeEventListener("mousedown", handleClickOutside);
        removeNoScroll(scrollPos.current);
      };
    } else {
      removeNoScroll(scrollPos.current);
    }
  }, [isOpen]);

  if (!isOpen || !data) return null;
  const modifiedName = data.title.toLowerCase().replace(/\s+/g, "-");

  const collageContent = (
    <>
      <img src={data.img} alt={data.name} />
      <div className="profile__title">
        <p>
          <strong>{data.name}</strong>
        </p>
        <p>{data.title}</p>
      </div>
    </>
  );

  const promoVariant1 = (
    <>
      <div className="popup__content">
        {data.title.split("\n").map((text, index) => (
          <h2 key={index}>
            {text}
            <br />
          </h2>
        ))}
        {data?.link && (
          <FindOutMoreBtn
            additionalClass={"popup__button"}
            goTo={data.link}
            onClick={() => {
              closePopup();
              handleClick("download");
            }}
          />
        )}
        <p>{data.description}</p>
      </div>
      <div className="popup__image">
        <img src={data.img} alt={data.name} />
      </div>
    </>
  );

  const promoVariant2 = (
    <>
      <div className="popup__image-container">
        {data.titleAboveImg && <h2 className="popup__title popup__title--top">{data.titleAboveImg}</h2>}
        <div className="popup__image">
          <img src={data.img} alt={data.name} />
        </div>
        {data.titleBelowImg && <h3 className="popup__title popup__title--bottom">{data.titleBelowImg}</h3>}
        {data?.link && (
          <FindOutMoreBtn
            additionalClass={"popup__button"}
            goTo={data.link}
            buttonText={"Download now"}
            download={true}
            onClick={() => {
              closePopup();
              handleClick("download");
            }}
          />
        )}
      </div>
    </>
  );

  return (
    <>
      {isOpen && (
        <div className="popup">
          <div className={`popup__inner popup__inner--${variant}`} ref={popupRef}>
            <button
              className="popup__close-button"
              onClick={() => {
                closePopup();
                handleClick("close");
              }}
            ></button>
            {variant === "collage" &&
              (biopage ? <a href={`/meet-the-team/${modifiedName}`}>{collageContent}</a> : collageContent)}
            {variant === "promo1" && promoVariant1}
            {variant === "promo2" && promoVariant2}
          </div>
        </div>
      )}
    </>
  );
}
